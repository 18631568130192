<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <!-- <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
      
      </div> -->
      <div class="cate" style="margin-top:5px">
        <span :class="lhActive=='A'?'selected':''" @click="setAOrB('A')">龙虎</span>
        <span :class="lhActive=='B'?'selected':''" @click="setAOrB('B')">龙虎B</span>
      </div>
      <div class="player listmain type-ssc xjsssc game-xjsssc game-ssc-lh">
        <!-- 龙 -->
        <div :class="'data clh_'+classArr[i] " v-for="(item,i) in rowsData" :key="item.title">
          <h3>{{item.title}}</h3>
          <ul class="custome_ul">
            <li v-for="ele in item.arr" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)" :key="ele.ResultID">
              <ol class="td_name" v-if="i==0">
                {{ele.label}}
              </ol>
              <ol class="td_rate">
                {{ele.Odds}}
              </ol>
              <ol class="td_cash">
                <input v-model="ele.money" type="number" @click.stop="showOrHideCheck($event, ele)" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click.stop="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>

          </ul>
        </div>

      </div>
      <yushe />
      <!-- 下面五球 表格 -->
      <div class="roadmap" v-if="currentGame.frequency == 'minite'">
        <!-- 表头 -->
        <table class="ltbinfo">
          <tbody>
            <tr>
              <th v-for="item in ltTitleArr" @click="changeQiu(item.index)" :class="item.index==qiuIndex?'selected':''" :key="item.title">{{item.title}}</th>

            </tr>
          </tbody>
        </table>
        <!-- 表格内容 -->
        <table colspan="0" id="lt_lh_b1-b2" cellspan="0" class="lttab" style="display: table">
          <tbody>
            <tr v-for="(item,index) in ltArr" :key="index">
              <td v-for="(ele,i) in item" :key="ele+i">
                <span :class="fontColor[ele]">{{ele}}</span>

              </td>

            </tr>
          </tbody>
        </table>
        <!-- 表格内容 -->
      </div>
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import mixins from "../mixins/mainMixins";
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      groupnames:'lh',
      ltArr: [],
      ltTitleArr: [
        { title: "1-2", index: 1 },
        { title: "1-3", index: 2 },
        { title: "1-4", index: 3 },
        { title: "1-5", index: 4 },
        { title: "2-3", index: 5 },
        { title: "2-4", index: 6 },
        { title: "2-5", index: 7 },
        { title: "3-4", index: 8 },
        { title: "3-5", index: 9 },
        { title: "4-5", index: 10 },
      ],
      lutu1: {
        arr1: [[], [], [], [], []],
      },

      lutu2: {
        arr1: [[], [], [], [], []], //单双
      },

      lutu3: {
        arr1: [[], [], [], [], []], //单
      },

      lutu4: {
        arr1: [[], [], [], [], []],
      },

      lutu5: {
        arr1: [[], [], [], [], []],
      },
      lutu6: {
        arr1: [[], [], [], [], []],
      },

      lutu7: {
        arr1: [[], [], [], [], []], //单双
      },

      lutu8: {
        arr1: [[], [], [], [], []], //单
      },

      lutu9: {
        arr1: [[], [], [], [], []],
      },

      lutu10: {
        arr1: [[], [], [], [], []],
      },
      // -----------
      longhu: "A",
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        { title: "龙", arr: [] },
        { title: "虎", arr: [] },
        { title: "和", arr: [] },
      ],
      classArr: ["l", "h", "d"],
      longA: [],
      longB: [],
      huA: [],
      huB: [],
      heA: [],
      heB: [],
      label1: [
        "1-2",
        "1-3",
        "1-4",
        "1-5",
        "2-3",
        "2-4",
        "2-5",
        "3-4",
        "3-5",
        "4-5",
      ],
    };
  },
  mixins: [mixins],
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label + "[" + `${item.title}` + "]",
                  Odds: ele.Odds,
                  title: this.longhu == "A" ? "龙虎" : "龙虎B",
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });
          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 龙A
        this.longA = this.ddFilterData1(
          resAArr,
          [3928, 3931, 3934, 1767, 3937, 3940, 3943, 3946, 3949, 3952],
          this.label1
        );
        // 虎A
        this.huA = this.ddFilterData1(
          resAArr,
          [3929, 3932, 3935, 1768, 3938, 3941, 3944, 3947, 3950, 3953],
          this.label1
        );
        // 和A
        this.heA = this.ddFilterData1(
          resAArr,
          [3930, 3933, 3936, 1769, 3939, 3942, 3945, 3948, 3951, 3954],
          this.label1
        );

        // this.rowsData[0].arr = this.longA;
        // this.rowsData[1].arr = this.huA;
        // this.rowsData[2].arr = this.heA;

        // 龙B
        this.longB = this.ddFilterData(
          resAArr,
          [92, 95, 98, 101, 104, 107, 110, 113, 116, 119],
          this.label1
        );

        // 虎B
        this.huB = this.ddFilterData(
          resAArr,
          [93, 96, 99, 102, 105, 108, 111, 114, 117, 120],
          this.label1
        );

        // 和B
        this.heB = this.ddFilterData(
          resAArr,
          [94, 97, 100, 103, 106, 109, 112, 115, 118, 121],
          this.label1
        );
        this.getAOrB();
        this.$forceUpdate();
      },
    },
  },
  created() {},

  methods: {
    getKJHis() {
      this.lutu1 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu2 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu3 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu4 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu5 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu6 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu7 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu8 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu9 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu10 = {
        arr1: [[], [], [], [], []],
      };
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 200,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            // 第一球----------
            let arr1 = [[]],
              arr2 = [[]],
              arr3 = [[]],
              arr4 = [[]],
              arr5 = [[]],
              arr6 = [[]],
              arr7 = [[]],
              arr8 = [[]],
              arr9 = [[]],
              arr10 = [[]];

            // --------------
            let list = resObj.data;
            list.reverse()
            list.forEach((element, index) => {
              element.codeArr = element.Opencode.split(",");

              let q1 = element.codeArr[0];
              let q2 = element.codeArr[1];
              let q3 = element.codeArr[2];
              let q4 = element.codeArr[3];
              let q5 = element.codeArr[4];
              // 1-2-----------------------
              let lh1 = "";
              if (q1 > q2) {
                lh1 = "龙";
              } else if (q1 < q2) {
                lh1 = "虎";
              } else {
                lh1 = "和";
              }
              this.initlutuData(arr1, lh1);
              // 1-3
              let lh2 = "";
              if (q1 > q3) {
                lh2 = "龙";
              } else if (q1 < q3) {
                lh2 = "虎";
              } else {
                lh2 = "和";
              }
              this.initlutuData(arr2, lh2);
              // 1-4
              let lh3 = "";
              if (q1 > q4) {
                lh3 = "龙";
              } else if (q1 < q4) {
                lh3 = "虎";
              } else {
                lh3 = "和";
              }
              this.initlutuData(arr3, lh3);
              // 1-5
              let lh4 = "";
              if (q1 > q5) {
                lh4 = "龙";
              } else if (q1 < q5) {
                lh4 = "虎";
              } else {
                lh4 = "和";
              }
              this.initlutuData(arr4, lh4);
              // 2-3
              let lh5 = "";
              if (q2 > q3) {
                lh5 = "龙";
              } else if (q2 < q3) {
                lh5 = "虎";
              } else {
                lh5 = "和";
              }
              this.initlutuData(arr5, lh5);
              // 2-4
              let lh6 = "";
              if (q2 > q4) {
                lh6 = "龙";
              } else if (q2 < q4) {
                lh6 = "虎";
              } else {
                lh6 = "和";
              }
              this.initlutuData(arr6, lh6);
              // 2-5
              let lh7 = "";
              if (q2 > q5) {
                lh7 = "龙";
              } else if (q2 < q5) {
                lh7 = "虎";
              } else {
                lh7 = "和";
              }
              this.initlutuData(arr7, lh7);
              // 3-4
              let lh8 = "";
              if (q3 > q4) {
                lh8 = "龙";
              } else if (q3 < q4) {
                lh8 = "虎";
              } else {
                lh8 = "和";
              }
              this.initlutuData(arr8, lh8);
              // 3-5
              let lh9 = "";
              if (q3 > q4) {
                lh9 = "龙";
              } else if (q3 < q4) {
                lh9 = "虎";
              } else {
                lh9 = "和";
              }
              this.initlutuData(arr9, lh9);
              // 4-5
              let lh10 = "";
              if (q4 > q5) {
                lh10 = "龙";
              } else if (q4 < q5) {
                lh10 = "虎";
              } else {
                lh10 = "和";
              }
              this.initlutuData(arr10, lh10);
              // ----------------------------------------------------------
            });
            //龙虎
            this.buquanArr(arr1, this.lutu1.arr1);
            this.buquanArr(arr2, this.lutu2.arr1);
            this.buquanArr(arr3, this.lutu3.arr1);
            this.buquanArr(arr4, this.lutu4.arr1);
            this.buquanArr(arr5, this.lutu5.arr1);
            this.buquanArr(arr6, this.lutu6.arr1);
            this.buquanArr(arr7, this.lutu7.arr1);
            this.buquanArr(arr8, this.lutu8.arr1);
            this.buquanArr(arr9, this.lutu9.arr1);
            this.buquanArr(arr10, this.lutu10.arr1);

            this.ltArr = this["lutu" + this.qiuIndex]["arr" + this.ltIndex];
            this.$forceUpdate();
          }
        } else {
        }
      });
    },
    setAOrB(str) {
      // this.longhu = str;
      this.$store.commit("SETLHACTIVE", str);
      if (str == "A") {
        this.rowsData[0].arr = this.longA;
        this.rowsData[1].arr = this.huA;
        this.rowsData[2].arr = this.heA;
      } else if (str == "B") {
        this.rowsData[0].arr = this.longB;
        this.rowsData[1].arr = this.huB;
        this.rowsData[2].arr = this.heA;
      }
      this.ISOPENON();
      this.$forceUpdate();
    },
    getAOrB() {
      if (this.lhActive == "A") {
        this.rowsData[0].arr = this.longA;
        this.rowsData[1].arr = this.huA;
        this.rowsData[2].arr = this.heA;
      } else if (this.lhActive == "B") {
        this.rowsData[0].arr = this.longB;
        this.rowsData[1].arr = this.huB;
        this.rowsData[2].arr = this.heA;
      }
      this.ISOPENON();
      this.$forceUpdate();
    },
    ISOPENON() {
      if (this.ISOPEN) {
        this.rowsData.forEach((item) => {
          item.arr.forEach((ele) => {
            ele.Odds = ele.recordOdds;
          });
        });
      } else {
        this.rowsData.forEach((item) => {
          item.arr.forEach((ele) => {
            ele.Odds = "--";
          });
        });
      }
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
    closeCheck(ele) {
      ele.b = false;
      this.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.custome_ul {
  height: 260px;
}
.listmain > .data > ul > li {
  height: 26px;
  min-height: 26px;
}
.listmain.game-ssc-kd .data,
.listmain.game-ssc-lh .data,
.listmain.game-ssc-qt .data {
  margin-right: 2px;
  width: calc((100% - 6px) / 3);
}
</style>
